import {useQuery} from '@tanstack/react-query';
import {getDataFromLocalOrCdn} from '@/utils/files';

export interface IDisabledSections {
  [setId: string]: {
    sections: string[];
  };
}

export async function getDisabledSections(): Promise<IDisabledSections> {
  const data = await getDataFromLocalOrCdn<IDisabledSections>(
    'disabled_sections.json',
  );

  if (data.data) {
    return data?.data;
  }

  return {};
}

export const DISABLED_SECTIONS_QUERY_KEY = ['disabled-sections'];

export function useDisabledSections(options = {}) {
  return useQuery<IDisabledSections>({
    queryKey: DISABLED_SECTIONS_QUERY_KEY,
    queryFn: async () => await getDisabledSections(),
    placeholderData: {},
    ...options,
  });
}
