import {
  ICustomHeroCta,
  EnhancedContentSanityResponse,
  IFeaturedHero,
  ICustomHeroImage,
  ICustomHero,
} from '@/models';
import {CustomHeroCta} from '@/models/sanity';
import {
  CustomHeroCtaDto,
  CustomHeroImageDto,
  FetchBrandDto,
} from '@/services/sanity/queries/fetchBrandDto';

const getCta = (cta?: CustomHeroCtaDto): ICustomHeroCta | null => {
  if (!cta) return null;
  return {
    text: cta.actionText ?? '',
    link: cta.link ?? '',
  };
};

const getCtas = (
  ctas?: CustomHeroCta[],
): {cta1: ICustomHeroCta | null; cta2: ICustomHeroCta | null} => {
  const defaultResult = {
    cta1: null,
    cta2: null,
  };
  if (!ctas || !Array.isArray(ctas) || ctas.length === 0) {
    return defaultResult;
  }
  const ctaQty = ctas.length;
  if (ctaQty === 1) {
    return {
      ...defaultResult,
      cta1: getCta(ctas[0]),
    };
  }
  if (ctaQty > 1) {
    return {
      cta1: getCta(ctas[0]),
      cta2: getCta(ctas[1]),
    };
  }
  return defaultResult;
};

const getImage = (
  image: CustomHeroImageDto | null,
): ICustomHeroImage | null => {
  if (!image) return null;
  if (!image.url || !image.width.uploaded || !image.height.uploaded)
    return null;
  return {
    url: image.url,
    width: image.width,
    height: image.height,
    alt: image.alt ?? '',
  };
};

export const getCustomHero = (response: FetchBrandDto): ICustomHero | null => {
  if (!response.featuredHero?.customHero) return null;
  const responseCustomHero = response.featuredHero.customHero;
  return {
    image: getImage(responseCustomHero?.image),
    title: responseCustomHero?.headline ?? null,
    description: responseCustomHero?.subheading ?? null,
    ...getCtas(responseCustomHero?.callsToAction),
  };
};

export const getFeaturedHero = (
  response: FetchBrandDto,
): IFeaturedHero | null => {
  if (!response.featuredHero?.brandHero) return null;
  return response.featuredHero.brandHero;
};

export const mapToEnhancedContentContenfulResponseDto = (
  response: FetchBrandDto,
): EnhancedContentSanityResponse => ({
  featuredHero: getFeaturedHero(response),
  customHero: getCustomHero(response),
});
