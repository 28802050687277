'use client';
import {
  FC,
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {DrugSearchResult, IResult} from '@/interfaces';

const SELECTED_RESULTS_LIMIT = 2;

interface SearchListProps {
  selectedResults: IResult[];
}

interface ISearchListContext {
  isSearchDisabled: boolean;
  isTyping: boolean;
  query: string;
  selectedValue: DrugSearchResult | undefined;
  selectedResults: IResult[];
  wasModified: boolean;
  showSelectedResults: boolean;
  addToSelectedResults: (_result: IResult) => void;
  removeFromSelectedResults: (_set_id: string) => void;
  setIsTyping: (_value: boolean) => void;
  setQuery: (_query: string) => void;
  setSelectedValue: (_searchedItem: DrugSearchResult) => void;
  setShowSelectedResults: (_value: boolean) => void;
}

const defaultState = {
  showSelectedResults: false,
  isSearchDisabled: false,
  isTyping: false,
  query: '',
  selectedValue: undefined,
  selectedResults: [],
  wasModified: false,
  addToSelectedResults: () => {},
  removeFromSelectedResults: () => {},
  setIsTyping: () => {},
  setQuery: () => {},
  setSelectedValue: () => {},
  setShowSelectedResults: () => {},
};

export const SearchListContext =
  createContext<ISearchListContext>(defaultState);

export const useSearchListContext = () => useContext(SearchListContext);

export const SearchListProvider: FC<SearchListProps> = ({
  selectedResults: defaultSelectedResults = [],
  ...props
}) => {
  const [query, setQuery] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<DrugSearchResult>();
  const [wasModified, setWasModified] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [showSelectedResults, setShowSelectedResults] = useState(true);

  const [selectedResults, setSelectedResults] = useState<IResult[]>(
    defaultSelectedResults,
  );

  const removeFromSelectedResults = useCallback((set_id: string) => {
    setSelectedResults((prev) => prev.filter((r) => r.set_id !== set_id));
  }, []);

  const addToSelectedResults = useCallback((result: IResult) => {
    setWasModified(true);
    setSelectedResults((prev) => [...prev, result]);
  }, []);

  const isSearchDisabled = useMemo(
    () => selectedResults?.length >= SELECTED_RESULTS_LIMIT,
    [selectedResults?.length],
  );

  const values = useMemo(
    () => ({
      isSearchDisabled,
      isTyping,
      query,
      selectedValue,
      selectedResults,
      wasModified,
      addToSelectedResults,
      removeFromSelectedResults,
      setIsTyping,
      setQuery,
      setSelectedValue,
      showSelectedResults,
      setShowSelectedResults,
    }),
    [
      isSearchDisabled,
      isTyping,
      query,
      selectedValue,
      selectedResults,
      wasModified,
      addToSelectedResults,
      removeFromSelectedResults,
      setIsTyping,
      showSelectedResults,
    ],
  );

  return <SearchListContext.Provider value={values} {...props} />;
};
