import {useQuery, QueryClient} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {ProductDto, LabelBasicInfo} from '@/models';
import {read} from '@/services/api';
import {toTitleCase} from '@/utils/string';
const DUPIXENT_SET_ID = '595f437d-2729-40bb-9c62-c8ece1f82780';
const DUPIXENT_FILTERED_NAME = 'DUPIXENT 100 MG in 0.67 ML Prefilled Syringe';

export async function getLabel(setId: string): Promise<ProductDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = await read<ProductDto>('labels', {resourceId: setId}, {}, 'nextApi');
    // Temporary fix for dupixent only
    if (data.id === DUPIXENT_SET_ID) {
      data = {
        ...data,
        prescribableNames: data?.prescribableNames?.filter(
          (n) => n !== DUPIXENT_FILTERED_NAME,
        ),
      };
    }
  } catch (e) {
    console.error(e);
  }

  return data;
}

export async function getLabelsBySetIds(setIds: string[]) {
  try {
    const uniqueSetIds = setIds.filter(
      (id, index) => setIds.indexOf(id) === index,
    );
    const labels =
      (await read<LabelBasicInfo[]>(
        'labels/bySetIds',
        {params: {setIds: uniqueSetIds.join(',')}},
        {},
        'nextApi',
      )) ?? [];

    return labels.map((label) => ({
      ...label,
      name: toTitleCase(label.title || ''),
    }));
  } catch (error) {
    console.error(error);
  }
}


export const getLabelQueryKey = (setId: string) => ['label', setId];

export function useLabel(setId: string, options = {}) {
  return useQuery<ProductDto | null>({
    queryKey: getLabelQueryKey(setId),
    queryFn: () => getLabel(setId),
    ...options,
  });
}

export function getLabelPrefetchQuery(setId: string): IPrefetchedQuery {
  return {
    queryKey: getLabelQueryKey(setId),
    getFn: async () => await getLabel(setId),
  };
}

export async function fetchQueryLabel(setId: string, queryClient: QueryClient) {
  return await queryClient.fetchQuery({
    queryKey: getLabelQueryKey(setId),
    queryFn: () => getLabel(setId),
  });
}
