import {BrandAsset} from './sanity';
import {HighlightSectionType} from '@/modules/ProductDetailPageNew/Header/HighlightSection/models';
import {CustomHeroTitleDto} from '@/services/sanity/queries/fetchBrandDto';

export interface EnhancedContentServiceCapabilitiesDto {
  name: string;
  confirmation: boolean;
}

export interface EnhancedContentLinkTagDto {
  name: string;
  id?: string;
}

export interface EnhancedContentLinkDto {
  tags: EnhancedContentLinkTagDto[];
  title: string;
  href: string;
}

export interface FaqDto {
  question: string;
  acceptedAnswer: string;
}

export interface DosagesDto {
  administration: string;
  dosing: string;
  latinShorthand: string;
  medhIndicationHeading: string;
}

export interface CostDto {
  annualCap: string;
  assistanceExpiration: string;
  coPayOutOfPocket: string;
  genericsAvailable: boolean;
}

export interface IOncologyBiomarkerDto {
  geneName: string;
  testMethods?: string[];
  testingMethodology: string;
  therapySelection: string;
}

export interface IFinancialAssistanceProgramDto {
  annualCap: number;
  costWhenEnrolled: number;
  expirationDate?: string;
  insuranceRequirements: string;
  minimumAge: number;
  monthlyCap?: number;
  supply: number;
  supplyLength: string;
  unitRetailPrice?: number;
  unitType: string;
  unitedStatesResidencyRequired: boolean;
  programType?: string;
}

export interface IFoundationAssistanceProgramDto {
  isAvailableForUninsured: boolean;
  isAvailableForMedicare: boolean;
  requirements: string[];
  multiplier: number;
  name: string;
}

export interface ICustomHeroCta {
  text: string;
  link: string;
}

export interface CustomHeroImageSizes {
  desktop?: number;
  tablet?: number;
  mobile?: number;
  uploaded: number;
}

export interface ICustomHeroImage {
  url: string;
  width: CustomHeroImageSizes;
  height: CustomHeroImageSizes;
  alt: string;
}

export interface ICustomHero {
  title: CustomHeroTitleDto | null;
  description: string | null;
  cta1: ICustomHeroCta | null;
  cta2: ICustomHeroCta | null;
  image: ICustomHeroImage | null;
}

export type IFeaturedHero = HighlightSectionType | 'customHero';

export interface IEnhancedContent {
  setId: string;
  brandName: string;
  enableSanityEnhanced: boolean;
  disableBrandAi: boolean | null;
  samplesAvailable: boolean | null;
  coverageEnabled: boolean | null;
  disableBrandFaq: boolean | null;
  atfRefreshLive: boolean | null;
  disableMostRecentPaFormsSection: boolean | null;
  brandDescription: string;
  therapeuticAreas: string[];
  links: EnhancedContentLinkDto[];
  faqs?: FaqDto[];
  dosages: DosagesDto[];
  cost: CostDto;
  adverseReactions: string;
  moas: string;
  specificPopulations: FaqDto[];
  oncologyBiomarkers?: IOncologyBiomarkerDto[];
  copaySavingsCardProgram: IFinancialAssistanceProgramDto | null;
  patientAssistanceProgram: IFinancialAssistanceProgramDto | null;
  financialAssistanceProgram: IFinancialAssistanceProgramDto | null;
  foundationAssistanceProgram: IFoundationAssistanceProgramDto | null;
  disableBrandBookmarking: boolean | null;
  disableBrandShare: boolean | null;
  disableBrandMsl: boolean | null;
  disableBrandDosage: boolean | null;
  disableBrandPatientEducation: boolean | null;
  disableBrandLabel: boolean | null;
  disableBrandPriorAuthorizationForms: boolean | null;
  disableBrandFinancialAssistancePrograms: boolean | null;
  disableBrandPubMed: boolean | null;
  hasSanityFinancialAssistancePrograms?: boolean;
  featuredHero: IFeaturedHero | null;
  customHero: ICustomHero | null;
}

interface IEnhancedContentContenfulFoudation {
  federalPovertyLevelPercentage: number;
  insuranceRequirements: string[];
  name: string;
  multiplier: number;
}

export interface IComparisonPageExpansionCollection {
  moa: string;
  adverseReactions: string;
  costs: CostDto;
  specificPopulationsCollection: {
    items: [
      {
        jsonLdOutputFromAi: IContenfulQuestionBlock;
        dosagesCollection: {
          items: DosagesDto[];
        };
      },
    ];
  };
}

interface IAiGeneratedFaqCollection {
  items: [{jsonLdOutputFromAi: IContenfulQuestionBlock}];
}

export interface IContenfulQuestion {
  name: string;
  acceptedAnswer: {
    text: string;
  };
}
export interface IContenfulQuestionBlock {
  mainEntity: IContenfulQuestion[];
}

interface IExternalLinksCollection {
  items: IExternalLinksCollectionItem[];
}

export interface IExternalLinksCollectionItem {
  name: string;
  url: string;
  contentfulMetadata: {
    tags: EnhancedContentLinkTagDto[];
  };
}

export interface IEnhancedContentContenful {
  brandDescription: string;
  name: string;
  setId: string;
  therapeuticarea: string[];
  coverageEnabled: boolean | null;
  disableBrandAi: boolean | null;
  samplesAvailable: boolean | null;
  disableBrandFaq: boolean | null;
  atfRefreshLive: boolean | null;
  disableMostRecentPaFormsSection: boolean | null;
  patientAssistanceProgram: IFinancialAssistanceProgramDto | null;
  copaySavingsCardProgram: IFinancialAssistanceProgramDto | null;
  financialAssistanceProgram: IFinancialAssistanceProgramDto | null;
  foundationAssistanceProgram: IEnhancedContentContenfulFoudation | null;
  comparisonPageExpansionCollection: {
    items: IComparisonPageExpansionCollection[];
  };
  aiGeneratedFaqCollection: IAiGeneratedFaqCollection;
  externalLinksCollection: IExternalLinksCollection;
  oncologyBiomarkersCollection: {
    items: IOncologyBiomarkerDto[];
  };
  disableBrandBookmarking: boolean | null;
  disableBrandShare: boolean | null;
  disableBrandMsl: boolean | null;
  disableBrandDosage: boolean | null;
  disableBrandPatientEducation: boolean | null;
  disableBrandLabel: boolean | null;
  disableBrandPriorAuthorizationForms: boolean | null;
  disableBrandFinancialAssistancePrograms: boolean | null;
  disableBrandPubMed: boolean | null;
}

export interface EnhancedContentContenfulDto {
  brandCollection: {
    items: IEnhancedContentContenful[];
  };
}

export interface EnhancedContentDto {
  enhancedContent: IEnhancedContent;
}

export enum BrandAssetCategories {
  adminGuides = 'Administration Guides',
  financialAssistance = 'Financial Assistance and Copay Programs',
  priorAuthorization = 'Prior Authorization and Coverage Support',
  patientEducation = 'Patient Education Materials',
  dosingSafety = 'Dosing and Safety Information',
  clinicalStudies = 'Clinical Information and Studies',
  treatmentInitiation = 'Treatment Initiation and Patient Onboarding',
  adherenceAndPatientSupport = 'Adherence and Patient Support Tools',
  reimbursement = 'Reimbursement Information',
  specialtyPharmacy = 'Specialty Pharmacy Coordination',
  legal = 'Legal',
  unclassified = 'Unclassified',
}

export interface IBrandAsset {
  title: string;
  summary: string;
}

export interface IEnhancedContentSanity {
  setId: string;
  brandDescription: string;
  disableBrandLabel: boolean;
  disableBrandPubMed: boolean;
  genericName: string;
  therapeuticAreas: string[];
  enableSanityEnhanced: boolean;
  disableBrandAi: boolean;
  disableBrandPriorAuthorizationForms: boolean;
  disableBrandDosage: boolean;
  specificPopulations: FaqDto[];
  assets: BrandAsset[];
  disabledCoverage: boolean;
  disableMostRecentPaFormsSection: boolean;
  disableBrandBookmarking: boolean;
  disableBrandShare: boolean;
  disableBrandPatientEducation: boolean;
  disableBrandFinancialAssistancePrograms: boolean;
  brandName: string;
  samplesAvailable: boolean;
  disableBrandFaq: boolean;
  disableBrandMsl: boolean;
  adverseReactions: string;
  faqs: FaqDto[];
  featuredHero: IFeaturedHero | null;
  customHero: ICustomHero | null;
  coverageEnabled?: boolean; // temporary to maintain compatibility with sanity
  hasSanityFinancialAssistancePrograms?: boolean;
  // This is to maintain compatibility with financial assistance
  copaySavingsCardProgram?: IFinancialAssistanceProgramDto | null;
  patientAssistanceProgram?: IFinancialAssistanceProgramDto | null;
  financialAssistanceProgram?: IFinancialAssistanceProgramDto | null;
  foundationAssistanceProgram?: IFoundationAssistanceProgramDto | null;
}

export interface EnhancedContentSanityResponse {
  featuredHero: IEnhancedContent['featuredHero'];
  customHero: IEnhancedContent['customHero'];
}

export type EnhancedContentFinancialAssistanceSanityResponse = {
  hasSanityFinancialAssistancePrograms: EnhancedContentDto['enhancedContent']['hasSanityFinancialAssistancePrograms'];
};

export interface EnhancedContentContenfulResponse {
  enhancedContent: Omit<
    IEnhancedContent,
    'customHero' | 'featuredHero' | 'hasSanityFinancialAssistancePrograms'
  >;
}
