export interface IRequestParams {
  parentName?: string;
  parentId?: string | number;
  resourceId?: string | number;
  params?: any;
  subResourceName?: string;
}

export interface IAPIResponse<T> {
  ok: boolean;
  status: number;
  data: T;
}

export interface IError {
  field: string;
  message: string;
}

export interface IErrorData {
  message?: string;
  errors?: Array<IError>;
  errorMessage?: string;
  code?: string;
}

export interface IErrorAttributes {
  url: string;
  status: number;
  data: unknown;
  errorSource: string;
  headers?: HeadersInit;
  fingerprint?: string;
}

export class APIError extends Error {
  data: IErrorData;
  code: number | string;

  constructor(status: number | string, data: IErrorData = {}) {
    super(`APIError ${status}`);
    this.code = status;
    this.name = data.message ?? '';
    this.data = data;
    if (data.errorMessage) {
      this.message = data.errorMessage;
    }
  }
}

export function requestPath(
  resourceName: string,
  requestParams: IRequestParams,
  options: RequestInit,
): string {
  const {parentName, parentId, resourceId, params} = requestParams;

  const parentPrefix =
    typeof parentName === 'undefined' ? '' : `${parentName}/`;

  const parentIdName = typeof parentId === 'undefined' ? '' : `${parentId}/`;

  const basePath = `${parentPrefix}${parentIdName}${resourceName}`;

  const subResource = requestParams.subResourceName ? `/${requestParams.subResourceName}` : '';

  const resourcePath =
    typeof resourceId === 'undefined' ? basePath : `${basePath}/${resourceId}${subResource}`;

  // to form querystrings when reading data
  if (params && options.method === 'GET') {
    const queryParams = {...params};

    Object.keys(queryParams).forEach(
      (key) => queryParams[key] === undefined && delete queryParams[key],
    );

    const queryString = new URLSearchParams(queryParams).toString();

    return queryString?.length === 0
      ? resourcePath
      : `${resourcePath}?${queryString}`;
  }

  return resourcePath;
}

export function requestOptions(
  requestParams: IRequestParams = {},
  options: RequestInit = {},
) {
  if (!requestParams.params) {
    return options;
  }

  return options.method === 'GET'
    ? options
    : {
        ...options,
        body: JSON.stringify({
          ...requestParams.params,
        }),
      };
}
