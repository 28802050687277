import {UserAgent} from '@/models/userAgent';

export const getIsIos = (ua: UserAgent) => ua.os.name === 'iOS';

export const getIsSafari = (ua: UserAgent) => {
  const {browser, engine} = ua;
  return browser.name === 'Safari' || engine.name === 'WebKit';
};

export const getIsIosOrSafari = (ua: UserAgent) =>
  getIsIos(ua) || getIsSafari(ua);
