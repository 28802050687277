import {usePathname, useSearchParams, useRouter} from 'next/navigation';
import {useCallback} from 'react';

export interface QueryParams {
  [key: string]: string | string[];
}

export function useQueryParams() {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const setParams = useCallback(
    (newParams: QueryParams) => {
      const params = new URLSearchParams(searchParams?.toString() ?? '');
      Object.entries(newParams).forEach(([name, value]) => {
        if (Array.isArray(value)) {
          params.delete(name);
          value.forEach((v) => params.append(name, v));
        } else if (value) {
          params.set(name, value);
        }
      });
      router.push(`${pathname}?${params.toString()}`);
    },
    [searchParams, pathname, router],
  );

  const deleteParams = useCallback(
    (keys: string[]) => {
      if (!searchParams) {
        return;
      }
      const params = new URLSearchParams(searchParams.toString());
      keys.forEach((key) => params.delete(key));
      router.push(`${pathname}?${params.toString()}`);
    },
    [searchParams, pathname, router],
  );

  const deleteAllParams = useCallback(() => {
    const params = new URLSearchParams();
    router.push(`${pathname}?${params.toString()}`);
  }, [router, pathname]);

  return {
    searchParams,
    pathname,
    router,
    setParams,
    deleteParams,
    deleteAllParams,
  };
}
