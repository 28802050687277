import {useQuery} from '@tanstack/react-query';
import {RemsDto} from '@/models';
import {read} from '@/services/api';

export async function getRemsBySetId(setId: string): Promise<RemsDto | null> {
  let data = null;

  try {
    data = await read<RemsDto>(
      setId,
      {
        parentName: 'rems',
      },
      {},
      'nextApi',
    );

    // Check if data is an empty object
    if (Object.keys(data).length === 0) {
      data = null;
    }
  } catch (e) {
    console.error(e);
  }
  return data;
}

export const getRemsBySetIdQueryKey = (setId: string) => ['rems', setId];

export function useRemsBySetId(setId: string, options = {}) {
  return useQuery<RemsDto | null>({
    queryKey: getRemsBySetIdQueryKey(setId),
    queryFn: () => getRemsBySetId(setId),
    ...options,
  });
}
