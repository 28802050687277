import {createClient} from 'next-sanity';
import {fetchFinancialAssistanceProgramsByBrandIdQuery} from './queries/fetchFinancialAssistanceProgramsByBrandIdQuery';
import {SANITY_DATASET, SANITY_PROJECT_ID} from '@/constants/global';
import type {FetchBrandDto} from '@/services/sanity/queries/fetchBrandDto';
import {fetchBrandQuery} from '@/services/sanity/queries/fetchBrandQuery';
import type {FetchFinancialAssistanceProgramDto} from '@/services/sanity/queries/fetchFinancialAssistanceProgramDto';
import {fetchFinancialAssistanceProgramQuery} from '@/services/sanity/queries/fetchFinancialAssistanceProgramQuery';

export type SanityClient = {
  fetchBrand: (_setId: string) => Promise<FetchBrandDto | null>;
  fetchFinancialAssistanceProgram: (
    _slug: string,
  ) => Promise<FetchFinancialAssistanceProgramDto | null>;
  fetchFinancialAssistanceProgramsByBrandId: (
    _brandId: string,
  ) => Promise<FetchFinancialAssistanceProgramDto[] | null>;
};

const baseClient = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  apiVersion: '2025-02-06',
  useCdn: false,
});

export const sanityClient: SanityClient = {
  fetchBrand: async (setId: string) => {
    try {
      const brand = await baseClient.fetch<FetchBrandDto | null>(
        fetchBrandQuery,
        {setId},
      );

      if (!brand) {
        return null;
      }

      return brand;
    } catch (error) {
      console.error('Error fetching from Sanity:', error);
      throw error;
    }
  },
  fetchFinancialAssistanceProgram: async (slug: string) => {
    try {
      const program =
        await baseClient.fetch<FetchFinancialAssistanceProgramDto | null>(
          fetchFinancialAssistanceProgramQuery,
          {slug},
        );

      if (!program || Object.keys(program).length === 0) {
        return null;
      }

      return program;
    } catch (error) {
      console.error('Error fetching from Sanity:', error);
      throw error;
    }
  },
  fetchFinancialAssistanceProgramsByBrandId: async (brandId: string) => {
    try {
      const programs = await baseClient.fetch<
        FetchFinancialAssistanceProgramDto[] | null
      >(fetchFinancialAssistanceProgramsByBrandIdQuery, {brandId});

      if (!programs || Object.keys(programs).length === 0) {
        return null;
      }

      return programs;
    } catch (error) {
      console.error('Error fetching from Sanity:', error);
      throw error;
    }
  },
};
