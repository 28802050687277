import {useQuery} from '@tanstack/react-query';
import {RecentDocumentsDto} from '@/models';
import {read} from '@/services/api';

export async function getRecentStateUniformForms(): Promise<RecentDocumentsDto | null> {
  return await read<RecentDocumentsDto>(
    'Recent',
    {
      params: {
        type: 'states',
      },
      parentName: 'Document',
    },
    {},
    'profile',
    false,
    1,
    404,
  );
}

export const RECENT_STATE_UNIFORM_FORMS_QUERY_KEY = ['recentStateUniformForms'];

export function useRecentStateUniformForms() {
  return useQuery<RecentDocumentsDto | null>({
    queryKey: RECENT_STATE_UNIFORM_FORMS_QUERY_KEY,
    queryFn: () => getRecentStateUniformForms(),
  });
}
