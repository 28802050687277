import {useQuery} from '@tanstack/react-query';
import {
  EnhancedContentContenfulResponse,
  EnhancedContentFinancialAssistanceSanityResponse,
  FaqDto,
  IEnhancedContent,
  IEnhancedContentSanity,
} from '@/models';
import {read} from '@/services/api';
import {
  getCustomHero,
  getFeaturedHero,
} from '@/services/sanity/mappers/fetchBrandMappers';
import {sanityClient} from '@/services/sanity/sanityClient';

export async function getEnhancedBrandSanity(
  setId: string,
): Promise<IEnhancedContentSanity | null> {
  let enhancedData = null;
  try {
    const response = await sanityClient.fetchBrand(setId);
    if (response) {
      const {
        setId,
        description,
        genericName,
        name,
        specificPopulations,
        faqs,
        enableSanityEnhanced,
        assets,
        adverseReactions,
        therapeuticAreas,
        samplesAvailable,
        disableBrandLabel,
        disableBrandPubMed,
        disableBrandAi,
        disableBrandPriorAuthorizationForms,
        disableBrandDosage,
        disableBrandFaq,
        disableBrandMsl,
        disabledCoverage,
        disableMostRecentPaFormsSection,
        disableBrandBookmarking,
        disableBrandShare,
        disableBrandPatientEducation,
        disableBrandFinancialAssistancePrograms,
      } = response;
      const parsedFaqs: FaqDto[] = [];
      const parsedSpecificPopulations: FaqDto[] = [];

      // parse faqs and specific population to avoid having null values
      faqs.forEach(({question, acceptedAnswer}) => {
        if (!question || !acceptedAnswer) return;
        parsedFaqs.push({
          question,
          acceptedAnswer,
        });
      });
      specificPopulations.forEach(({question, acceptedAnswer}) => {
        if (!question || !acceptedAnswer) return;
        parsedSpecificPopulations.push({
          question,
          acceptedAnswer,
        });
      });

      enhancedData = {
        setId,
        brandDescription: description,
        genericName,
        specificPopulations: parsedSpecificPopulations,
        assets,
        brandName: name,
        adverseReactions,
        enableSanityEnhanced,
        samplesAvailable,
        therapeuticAreas: therapeuticAreas ?? [],
        disableBrandFaq,
        disableBrandMsl,
        disabledCoverage,
        disableMostRecentPaFormsSection,
        disableBrandBookmarking,
        disableBrandShare,
        disableBrandPatientEducation,
        disableBrandFinancialAssistancePrograms,
        disableBrandLabel,
        disableBrandPubMed,
        disableBrandAi,
        disableBrandPriorAuthorizationForms,
        disableBrandDosage,
        coverageEnabled: !disabledCoverage,
        faqs: parsedFaqs,
        featuredHero: getFeaturedHero(response),
        customHero: getCustomHero(response),
      };
    }
  } catch (e) {
    console.error(e);
  }
  return enhancedData;
}

export async function getEnhancedContent(
  setId: string,
): Promise<IEnhancedContent | IEnhancedContentSanity | null> {
  if (!setId) return null;

  let contentfulData = null;
  let sanityData = null;
  let sanityFinancialAssistanceData = null;

  try {
    const contentfulResponse = await read<EnhancedContentContenfulResponse>(
      'enhancedContent',
      {parentName: 'contentful', resourceId: setId},
      {},
      'nextApi',
    );
    contentfulData = contentfulResponse?.enhancedContent as IEnhancedContent;
  } catch (e) {
    console.error(e);
  }

  sanityData = await getEnhancedBrandSanity(setId);
  const enableSanityForBrand = sanityData?.enableSanityEnhanced ?? false;

  try {
    sanityFinancialAssistanceData =
      await read<EnhancedContentFinancialAssistanceSanityResponse | null>(
        'brands',
        {
          parentName: 'sanity',
          resourceId: setId,
          subResourceName: 'financialAssistancePrograms',
        },
        {},
        'nextApi',
      );
  } catch (e) {
    console.error(e);
  }

  if (enableSanityForBrand) {
    return sanityData
      ? {
          ...sanityData,
          copaySavingsCardProgram: contentfulData?.copaySavingsCardProgram,
          patientAssistanceProgram: contentfulData?.patientAssistanceProgram,
          financialAssistanceProgram:
            contentfulData?.financialAssistanceProgram,
          foundationAssistanceProgram:
            contentfulData?.foundationAssistanceProgram,
          hasSanityFinancialAssistancePrograms: !!sanityFinancialAssistanceData,
        }
      : null;
  }

  if (!contentfulData) return null;

  const data = {
    ...contentfulData,
    enableSanityEnhanced: enableSanityForBrand,
    featuredHero: sanityData?.featuredHero ?? null,
    customHero: sanityData?.customHero ?? null,
    hasSanityFinancialAssistancePrograms: !!sanityFinancialAssistanceData,
  };

  return data;
}

export const getEnhancedContentQueryKey = (setId: string) => [
  'enhanced-content',
  setId,
];

export function useEnhancedContent(setId: string, options = {}) {
  return useQuery<IEnhancedContent | IEnhancedContentSanity | null>({
    queryKey: getEnhancedContentQueryKey(setId),
    queryFn: () => getEnhancedContent(setId),
    ...options,
  });
}
