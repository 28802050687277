import {useCallback, useEffect, useMemo, useState} from 'react';
import useDebounce from './useDebounce';
import {useQueryParams} from './useQueryParams';

export type UseRangeFilterProps = {
  ranges: {min: number; max: number};
  setRanges: (_range: {min: number; max: number}) => void;
  handleChange: (_range: {min: number; max: number}) => void;
  resetRangeFilters: () => void;
};

export const rangesInitialState = {min: 0, max: 100};

const useRangeFilter = ({
  initialRanges,
  paramKey,
}: {
  initialRanges: {min: number; max: number};
  paramKey: string;
}): UseRangeFilterProps => {
  const {setParams, searchParams} = useQueryParams();

  const [ranges, setRanges] = useState(initialRanges);
  const [hasChanged, setHasChanged] = useState(false);
  const debouncedRanges = useDebounce(ranges, 500);
  const debouncedChange = useDebounce(hasChanged, 500);

  const shouldUpdateParams = useMemo(
    () =>
      debouncedRanges &&
      debouncedChange &&
      debouncedRanges.max === ranges.max &&
      debouncedRanges.min === ranges.min,
    [debouncedRanges, ranges.max, ranges.min, debouncedChange],
  );

  useEffect(() => {
    const key = searchParams.get(paramKey);
    if (key) {
      try {
        const parsedKey = JSON.parse(key);
        setRanges(parsedKey);
      } catch (error) {
        console.error(error);
      }
    }
  }, [paramKey, searchParams]);

  useEffect(() => {
    if (shouldUpdateParams) {
      setParams({[paramKey]: JSON.stringify(debouncedRanges)});
    }
  }, [debouncedRanges, paramKey, setParams, shouldUpdateParams]);

  const handleChange = useCallback((range: {min: number; max: number}) => {
    setRanges(range);
    setHasChanged(true);
  }, []);

  const resetRangeFilters = useCallback(() => {
    setRanges(initialRanges);
    setHasChanged(false);
  }, [initialRanges]);

  return {
    ranges,
    setRanges,
    handleChange,
    resetRangeFilters,
  };
};

export default useRangeFilter;
