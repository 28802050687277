'use client';
import {FC, createContext, useContext, ReactNode, useMemo} from 'react';

export type FlagsType = Record<string, 'off' | 'on'>;

interface IFeatureFlags {
  flags: FlagsType;
}

const defaultState: IFeatureFlags = {
  flags: {},
};

export const FlagsContext = createContext<IFeatureFlags>(defaultState);

export const useFlags = () => useContext(FlagsContext);

interface FlagsProviderProps {
  flags: FlagsType;
  children: ReactNode;
}

export const FlagsProvider: FC<FlagsProviderProps> = ({flags, children}) => {
  const values = useMemo(
    () => ({
      flags,
    }),
    [flags],
  );

  return (
    <FlagsContext.Provider value={values}>{children}</FlagsContext.Provider>
  );
};

export const useFlag = (name: string, value: string = 'on') => {
  const {flags} = useFlags();
  return flags?.[name] === value;
};
