import {getLabelTitle, getProductFAQData} from './product';
import {getSetIdFromPage} from './slug';
import {
  FaqDto,
  IEnhancedContent,
  IEnhancedContentSanity,
} from '@/models/enhancedContentDto';
import {ProductDto} from '@/models/productDto';
import {getPage} from '@/queries/usePage';

export function getDrugName(
  product?: ProductDto | null,
  enhancedContentDto?: IEnhancedContent | IEnhancedContentSanity | null,
) {
  const drugName =
    enhancedContentDto?.brandName ??
    getLabelTitle(product?.names, product?.label?.title);
  return drugName;
}

export function getFaqData(product?: ProductDto | null, faqs?: FaqDto[]) {
  // Variables for google rich snippets
  const {dosages, howAdministrated, indications, madeOf, moa, name} =
    getProductFAQData(product ?? null);

  let faqMainEntityData = '';

  if (faqs && faqs.length > 0) {
    const mapped = faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.acceptedAnswer,
      },
    }));
    faqMainEntityData = JSON.stringify(mapped);
  } else {
    faqMainEntityData = `[
              {
                "@type": "Question",
                "name": "What is the dosage of ${name}?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${dosages}"
                }
              },
              {
                "@type": "Question",
                "name": "What does ${name} treat?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${indications}"
                }
              },
              {
                "@type": "Question",
                "name": "What is ${name} made of?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${madeOf}"
                }
              },
              {
                "@type": "Question",
                "name": "How is ${name} administered?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${howAdministrated}"
                }
              },
              {
                "@type": "Question",
                "name": "What is ${name} mechanism of action?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${moa}"
                }
              }
            ]`;
  }

  return {faqMainEntityData, faqName: name};
}

export const PDP_REGEX = /\/therapies\/[a-z].+-[a-z0-9]{7}(abc)?\/?/;
export const getIsPDP = (path: string) => PDP_REGEX.test(path);

export const SUB_PDP_PAGE_REGEX = /\/therapies\/[a-z].+-[a-z0-9]{7}(abc)?\/.+/;
export const getIsPDPSubpage = (path: string) => SUB_PDP_PAGE_REGEX.test(path);

export async function getSetId(brandSlug: string) {
  const page = await getPage(brandSlug);

  return page ? getSetIdFromPage(page) : null;
}
