import {useQuery} from '@tanstack/react-query';
import {RecentDocumentsDto} from '@/models';
import {read} from '@/services/api';

const MAX_OF_ITEMS = 5;

export async function getRecentPAForms(
  setId: string,
): Promise<RecentDocumentsDto | null> {
  return await read<RecentDocumentsDto>(
    'RecentByBrand',
    {
      params: {
        setId,
        type: 'issuer',
        top: MAX_OF_ITEMS,
      },
      parentName: 'Document',
    },
    {},
    'profile',
    false,
    1,
    404,
  );
}

export const getRecentPAFormsQueryKey = (setId: string) => [
  'recentPaForms',
  setId,
];

export function useRecentPaForms(setId: string) {
  return useQuery<RecentDocumentsDto | null>({
    queryKey: ['recentPaForms', setId],
    queryFn: () => getRecentPAForms(setId),
  });
}
