import {useMemo} from 'react';
import {OTHER_SECTION_IDS, SECTION_IDS} from '@/constants/sectionIDs';
import {
  BrandAssetCategories,
  EnhancedContentLinkDto,
  IEnhancedContent,
  IEnhancedContentSanity,
} from '@/models';
import {useEnhancedContent} from '@/queries/useEnhancedContent';
import {addResourceUtms} from '@/utils';
import {getEnhancedContentValues} from '@/utils/enhancedContent';

export const PARENT_SECTION_ID_MAP = {
  [OTHER_SECTION_IDS.msl]: 'parentSectionMedicalScienceLiaison',
  [OTHER_SECTION_IDS.reportAdverseEvent]: 'parentSectionReportAdverseEvent',
  [SECTION_IDS.priorAuthorization]: 'parentSectionPriorAuthorization',
  [SECTION_IDS.financialAssistance]: 'parentSectionFinancialAssistanceSupport',
  [SECTION_IDS.pubMed]: 'parentSectionPubMed',
  [SECTION_IDS.patientEducation]: 'parentSectionPatientEducation',
};

export const SANITY_CATEGORIES = [
  'Administration Guides',
  'Financial Assistance and Copay Programs',
  'Prior Authorization and Coverage Support',
  'Patient Education Materials',
  'Dosing and Safety Information',
  'Clinical Information and Studies',
  'Treatment Initiation and Patient Onboarding',
  'Adherence and Patient Support Tools',
  'Reimbursement Information',
  'Specialty Pharmacy Coordination',
  'Legal',
  'Unclassified',
];

export const SANITY_PDP_SECTION_ID_MAP = {
  [OTHER_SECTION_IDS.msl]: BrandAssetCategories.legal,
  [OTHER_SECTION_IDS.reportAdverseEvent]: 'parentSectionReportAdverseEvent',
  [SECTION_IDS.priorAuthorization]: BrandAssetCategories.priorAuthorization,
  [SECTION_IDS.financialAssistance]: BrandAssetCategories.financialAssistance,
  [SECTION_IDS.pubMed]: BrandAssetCategories.clinicalStudies,
  [SECTION_IDS.patientEducation]: BrandAssetCategories.patientEducation,
};

export function getSectionResources(
  resources: EnhancedContentLinkDto[],
  sectionId: string,
) {
  return resources.filter(({tags}) =>
    tags.find(({name, id}) => name === 'Section' && id === sectionId),
  );
}

export function getSection(
  resources: EnhancedContentLinkDto[],
  sectionId: string,
) {
  return resources.find(({tags}) =>
    tags.find(({name, id}) => name === 'Section' && id === sectionId),
  );
}

export function useEnhanced(setId: string, parentSectionId?: string) {
  const {
    isPaused,
    data: enhancedContent,
    isFetching,
  } = useEnhancedContent(setId, {
    enabled: !!setId,
  });

  const {
    disableAi,
    samplesAvailable,
    disableBrandFaq,
    isCoverageEnabled,
    disableMostRecentPaFormsSection,
    disableBrandBookmarking,
    disableBrandShare,
    disableBrandMsl,
    disableBrandDosage,
    disableBrandPatientEducation,
    disableBrandLabel,
    disableBrandPriorAuthorizationForms,
    disableBrandFinancialAssistancePrograms,
    disableBrandPubMed,
    hasSanityFinancialAssistancePrograms,
    featuredHero,
    customHero,
  } = getEnhancedContentValues(enhancedContent);

  const resources = useMemo(() => {
    if (!enhancedContent || !parentSectionId || isPaused) {
      return [];
    }

    let enhancedResources = [];

    if (enhancedContent.enableSanityEnhanced) {
      enhancedResources =
        (enhancedContent as IEnhancedContentSanity)?.assets ?? [];
      enhancedResources = enhancedResources
        .filter(
          (asset) =>
            asset.category === SANITY_PDP_SECTION_ID_MAP[parentSectionId],
        )
        .map((asset) => ({
          title: asset.title,
          tags: [],
          href: addResourceUtms(asset.url ?? ''),
        })) as EnhancedContentLinkDto[];
    } else {
      enhancedResources = (enhancedContent as IEnhancedContent).links;
      enhancedResources = enhancedResources
        .filter(
          ({tags, href}) =>
            tags.find(
              ({name, id}) =>
                name === 'ParentSection' &&
                id === PARENT_SECTION_ID_MAP[parentSectionId],
            ) && href,
        )
        .map((resource) => ({
          ...resource,
          href: addResourceUtms(resource.href),
        }));
    }

    return enhancedResources;
  }, [enhancedContent, parentSectionId, isPaused]);

  return {
    isEnhanced: !!enhancedContent,
    enhancedContent,
    resources,
    isFetching,
    sanityEnabled: !!enhancedContent?.enableSanityEnhanced,
    disableAi,
    samplesAvailable,
    isCoverageEnabled,
    disableBrandFaq,
    disableMostRecentPaFormsSection,
    disableBrandBookmarking,
    disableBrandShare,
    disableBrandMsl,
    disableBrandDosage,
    disableBrandPatientEducation,
    disableBrandLabel,
    disableBrandPriorAuthorizationForms,
    disableBrandFinancialAssistancePrograms,
    disableBrandPubMed,
    featuredHero,
    customHero,
    hasSanityFinancialAssistancePrograms,
  };
}
