import {FetchQueryOptions} from '@tanstack/react-query';
import {replaceVariablesInStr} from './string';
import {
  COPAY_PROGRAM,
  FOUNDATION_PROGRAM,
  PATIENT_ASSISTANCE_PROGRAM,
} from '@/constants/financialAssistance';
import {
  POVERTY_GUIDELINES,
  IUsStateGuideline,
  FINANCIAL_ASSISTANCE_INFO,
  SAVINGS_PROGRAM_INFO,
} from '@/data/affordability';
import {
  IEnhancedContent,
  IEnhancedContentSanity,
  IFinancialAssistanceProgramDto,
  IFoundationAssistanceProgramDto,
} from '@/models';
import {
  CONSTELLATION_DRUG_QUERY_KEY,
  getConstellationDrugs,
  getDrugIndications,
  getDrugIndicationsQueryKey,
  getLabel,
  getLabelQueryKey,
  getRecentStateUniformForms,
  RECENT_STATE_UNIFORM_FORMS_QUERY_KEY,
} from '@/queries';
import {
  getEnhancedContent,
  getEnhancedContentQueryKey,
} from '@/queries/useEnhancedContent';
import {getPage, getPageQueryKey} from '@/queries/usePage';

export function getCopayProgramDetails(
  copayProgram: IFinancialAssistanceProgramDto | undefined | null,
) {
  if (!copayProgram) return [];
  const details: string[] = [];
  const {expirationDate, annualCap} = copayProgram;
  const {
    details: {programAnnualCap, programExpiration},
  } = COPAY_PROGRAM;

  if (expirationDate)
    details.push(replaceVariablesInStr(programExpiration, {expirationDate}));
  if (annualCap)
    details.push(
      replaceVariablesInStr(programAnnualCap, {
        annualCap: annualCap.toString(),
      }),
    );
  return details;
}

export function getCopayPrice(copayProgram: IFinancialAssistanceProgramDto) {
  return replaceVariablesInStr(COPAY_PROGRAM.price, {
    price: copayProgram.costWhenEnrolled.toString(),
  });
}

export function getPatientProgramDetails(
  patientProgram: IFinancialAssistanceProgramDto | undefined | null,
) {
  return patientProgram
    ? PATIENT_ASSISTANCE_PROGRAM.details.map((detail) =>
        replaceVariablesInStr(detail, {
          unitType: patientProgram.unitType,
          expirationDate: patientProgram.expirationDate ?? '',
          annualCap: patientProgram.annualCap?.toString() ?? '',
          unitRetailPrice: Math.round(
            patientProgram.unitRetailPrice ?? 0,
          ).toString(),
        }),
      )
    : [];
}
export function getPatientAssistancePrice(
  patientProgram: IFinancialAssistanceProgramDto,
) {
  return replaceVariablesInStr(PATIENT_ASSISTANCE_PROGRAM.price, {
    price: patientProgram.costWhenEnrolled.toString(),
    supply: patientProgram.supply.toString(),
    supplyLength: patientProgram.supplyLength.toLocaleLowerCase(),
  });
}

export function getFoundationPrograms(
  foundationAssistanceProgram: IFoundationAssistanceProgramDto,
) {
  const {isAvailableForMedicare, isAvailableForUninsured} =
    foundationAssistanceProgram;
  const programs = [];
  if (isAvailableForMedicare) {
    programs.push({
      icon: FOUNDATION_PROGRAM.programs.medicare.icon,
      name: 'medicare',
    });
  }
  if (isAvailableForUninsured) {
    programs.push({
      icon: FOUNDATION_PROGRAM.programs.uninsured.icon,
      name: 'uninsured',
    });
  }
  return programs;
}

export function getFinancialAssistanceProgram(
  data: IEnhancedContent | IEnhancedContentSanity | undefined,
) {
  return (
    data?.financialAssistanceProgram ??
    data?.copaySavingsCardProgram ??
    data?.patientAssistanceProgram ??
    null
  );
}

/**
  returns a boolean that represents if the patient is eligible for the foundation program
*/
export function evaluateFoundationAvailability(
  income: number,
  householdSize: number,
  multiplier: number,
  state: IUsStateGuideline,
) {
  const incomeTable = POVERTY_GUIDELINES[state];
  const idx = householdSize < 8 ? householdSize - 1 : 7;
  return income < multiplier * incomeTable[idx];
}

export function getFinancialAssistanceProgramName(
  insuranceType: string,
  isProgramAvailable: boolean,
  enhancedContent?: IEnhancedContent | IEnhancedContentSanity | null,
) {
  /** Important: Business rules
   *  1- If the user has commercial insurance could access to copay
   *     or pacient assistance.
   *  2- If the user has Medicare o doesn't have any insurance,
   *     could access only to foundation, if the user has an income equal or less than the income in the "tables".
   * */

  const NOT_AVAILABLE = 'Not available';

  if (!isProgramAvailable || !enhancedContent) return NOT_AVAILABLE;

  const foundationProgram =
    enhancedContent?.foundationAssistanceProgram ?? null;

  switch (insuranceType) {
    case 'commercial':
      return (
        getFinancialAssistanceProgram(enhancedContent)?.programType ??
        'Copay Card'
      );
    case 'medicare':
      if (foundationProgram) {
        const {programName} = FINANCIAL_ASSISTANCE_INFO;
        return programName;
      }
      return NOT_AVAILABLE;
    default:
      if (foundationProgram) {
        const {programName} = SAVINGS_PROGRAM_INFO;
        return programName;
      }
      return NOT_AVAILABLE;
  }
}

export const financialAssistanceFiltersQueryParams = {
  insuranceRequirements: 'f0',
  coverageRequired: 'f1',
  ageRequired: 'f2',
  fundedPrograms: 'f3',
  outOfPocket: 'f4',
  maxSavings: 'f5',
};

export const getFAPrefetchQueries = (brandSlug: string, setId: string) => {
  const queries: FetchQueryOptions[] = [
    {
      queryKey: CONSTELLATION_DRUG_QUERY_KEY,
      queryFn: () => getConstellationDrugs(),
    },
    {
      queryKey: getPageQueryKey(brandSlug),
      queryFn: () => getPage(brandSlug),
    },
    {
      queryKey: getLabelQueryKey(setId),
      queryFn: () => getLabel(setId),
    },
    {
      queryKey: getEnhancedContentQueryKey(setId),
      queryFn: () => getEnhancedContent(setId),
    },
    // useSectionsStatus queries
    {
      queryKey: getDrugIndicationsQueryKey(setId),
      queryFn: () => getDrugIndications(setId),
    },
    {
      queryKey: RECENT_STATE_UNIFORM_FORMS_QUERY_KEY,
      queryFn: () => getRecentStateUniformForms(),
    },
  ];
  return queries;
};
