'use client';
import {useIsMobile, useIsTablet} from '@prescriberpoint/ui';
import {FC, createContext, useContext, useMemo, ReactNode} from 'react';
import {UserAgent} from '@/models/userAgent';
import {getIsIosOrSafari} from '@/utils/userAgent';

interface IUserAgentContext {
  userAgent?: UserAgent;
  isMobile: boolean;
  isMobileOrTablet: boolean;
  isTablet: boolean;
  isIosOrSafari: boolean;
}

const defaultState: IUserAgentContext = {
  userAgent: undefined,
  isMobile: false,
  isMobileOrTablet: false,
  isTablet: false,
  isIosOrSafari: false,
};

interface UserAgentProps {
  userAgent: UserAgent;
  children: ReactNode;
}

const UserAgentContext = createContext<IUserAgentContext>(defaultState);

export const useUserAgentContext = () => useContext(UserAgentContext);

export const UserAgentProvider: FC<UserAgentProps> = ({
  children,
  userAgent,
}) => {
  const {
    device: {type},
  } = userAgent;
  const isClient = typeof window !== 'undefined';
  const isMobileScreen = useIsMobile();
  const isTabletScreen = useIsTablet();

  const isMobile = useMemo(
    () => type === 'mobile' || (isClient && isMobileScreen),
    [type, isMobileScreen, isClient],
  );

  const isTablet = useMemo(
    () => type === 'tablet' || (isClient && isTabletScreen),
    [type, isTabletScreen, isClient],
  );

  const isIosOrSafari = useMemo(() => getIsIosOrSafari(userAgent), [userAgent]);

  const values = useMemo(
    () => ({
      isMobile,
      isTablet,
      isMobileOrTablet: isMobile || isTablet,
      isIosOrSafari,
    }),
    [isMobile, isTablet, isIosOrSafari],
  );

  return (
    <UserAgentContext.Provider value={values}>
      {children}
    </UserAgentContext.Provider>
  );
};
