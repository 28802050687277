import {IPageSection} from '@/interfaces';

const PATIENT_EDUCATION_SECTION: IPageSection = {
  title: '{brandName} Patient Education',
  sections: [
    {
      sectionId: 'sectionInstructionsForUse',
      title: 'Getting started on {brandName}',
    },
    {
      sectionId: 'sectionPatientToolkit',
      title: 'Patient toolkit',
    },
    {
      sectionId: 'sectionOtherResources',
      title: 'Other resources',
    },
  ],
};

export default PATIENT_EDUCATION_SECTION;
