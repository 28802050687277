// Query to fetch all financial assistance programs related to a specific brand ID
export const fetchFinancialAssistanceProgramsByBrandIdQuery = `*[_type == "financialAssistanceProgram" && references($brandId)] {
  _id,
  programName,
  programId,
  programType,
  outOfPocket,
  summary,
  coverageEligibilities,
  benefits {
      _type,
      monthlySavings,
      annualMaximumBenefit,
      maximumFills
  },
  requirements {
      _type,
      expirationDate,
      minimumAge,
      usResidency,
      enrollmentRequired,
      needsBased,
      activationRequired,
      renewalRequired,
      coverageRequired
  },
  programDetails[],
  resources[],
  faqs[],
}`;
