import {useQuery} from '@tanstack/react-query';
import {AffordabilityDetailsResponse} from '@/models';
import {read} from '@/services/api';

export async function getAffordabilityDetails(
  setId: string,
): Promise<AffordabilityDetailsResponse> {
  const data = await read<AffordabilityDetailsResponse>(
    'affordability',
    {
      resourceId: setId,
    },
    {},
    'nextApi',
  );
  return data;
}

export const getAffordabilityDetailsQueryKey = (setId: string) => [
  'affordability-details',
  setId,
];

export function useAffordabilityDetails(setId: string, options = {}) {
  return useQuery<AffordabilityDetailsResponse>({
    queryKey: getAffordabilityDetailsQueryKey(setId),
    queryFn: async () => await getAffordabilityDetails(setId),
    ...options,
  });
}
