export const fetchBrandQuery = `*[_type == "brand" && _id == $setId][0]{
    'setId': _id,
    name,
    genericName,
    description,
    enableSanityEnhanced,
    assets,
    therapeuticAreas,
    samplesAvailable,
    disableBrandAi,
    disabledCoverage,
    disableBrandFaq,
    disableBrandPriorAuthorizationForms,
    disableMostRecentPaFormsSection,
    disableBrandBookmarking,
    disableBrandShare,
    disableBrandMsl,
    disableBrandDosage,
    disableBrandPatientEducation,
    disableBrandLabel,
    disableBrandFinancialAssistancePrograms,
    disableBrandPubMed,
    adverseReactions,
    faqs,
    specificPopulations,
    featuredHero {
      brandHero,
      customHero {
        "image": {
          "url": customHeroImage.image.asset->url,
          "width": {
            "desktop": customHeroImage.width.desktop,
            "tablet": customHeroImage.width.tablet,
            "mobile": customHeroImage.width.mobile,
            "uploaded": customHeroImage.image.asset->metadata.dimensions.width,
          },
          "height": {
            "desktop": customHeroImage.height.desktop,
            "tablet": customHeroImage.height.tablet,
            "mobile": customHeroImage.height.mobile,
            "uploaded": customHeroImage.image.asset->metadata.dimensions.height,
          },
          "alt": customHeroImage.alt,
        },
        headline,
        subheading,
        callsToAction[]
      }
    }
  }`;
