import {useQuery} from '@tanstack/react-query';
import {DDI_INGREDIENTS_BRAND_NAMES, DDI_TOOL} from '@/constants/flags';
import {useFlag} from '@/context';
import {IInteractionDataItem} from '@/models/interactions';
import {read} from '@/services/api';

export async function getInteractionsByDrug(
  setId: string,
  totalOnly: boolean,
  labelsNdSlugs: boolean = false,
): Promise<IInteractionDataItem | null> {
  let data = null;

  if (!setId) return data;

  try {
    return await read<IInteractionDataItem>(
      'interactionsByDrug',
      {
        parentName: 'ddi',
        params: {
          drug: setId,
          totalOnly: totalOnly,
          labelsNdSlugs,
        },
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export const getInteractionsByDrugQueryKey = (
  setId: string,
  totalOnly: boolean,
  ddiIngredientNamesEnabled: boolean,
) => ['interactions-by-drug', setId, totalOnly, ddiIngredientNamesEnabled];

export function useInteractionsByDrugQuery(
  setId: string,
  totalOnly: boolean = false,
  options = {},
) {
  const ddiIngredientNamesEnabled = useFlag(DDI_INGREDIENTS_BRAND_NAMES);

  return useQuery<IInteractionDataItem | null>({
    queryKey: getInteractionsByDrugQueryKey(
      setId,
      totalOnly,
      ddiIngredientNamesEnabled,
    ),
    queryFn: () =>
      getInteractionsByDrug(setId, totalOnly, ddiIngredientNamesEnabled),
    ...options,
  });
}

export const useInteractionsByDrug = (
  setId: string,
  totalOnly: boolean = false,
) => {
  const toolIsEnabled = useFlag(DDI_TOOL);

  const {data, ...restQueryProperties} = useInteractionsByDrugQuery(
    setId,
    totalOnly,
  );

  const showDDI = toolIsEnabled && !!data?.totalItems;

  return {
    data,
    showDDI,
    ...restQueryProperties,
  };
};
