import {useQuery} from '@tanstack/react-query';
import {IDrugIndication} from '@/models/coverage';
import {read} from '@/services/api';

export async function getDrugIndications(setId: string) {
  let data: IDrugIndication[] = [];

  try {
    data = await read<IDrugIndication[]>(
      'indications',
      {
        parentName: 'coverage',
        resourceId: setId,
      },
      {},
      'nextApi',
    );
  } catch (error) {
    console.error(error);
  }

  // when response is 204 it returns an empty object, causing the app to crash
  return data && Object.keys(data).length === 0 ? [] : data;
}

export const getDrugIndicationsQueryKey = (setId: string) => [
  'drug-indications',
  setId,
];

export function useDrugIndications(setId: string, params = {}) {
  return useQuery({
    queryKey: getDrugIndicationsQueryKey(setId),
    queryFn: () => getDrugIndications(setId),
    initialData: [],
    ...params,
  });
}
