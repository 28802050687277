import {PageDto} from '@/models';

export const getSetIdFromPage = (page: PageDto) =>
  page?.links?.find((e) => e.rel === 'api')?.href?.match('Labels/(.*)')![1]!;

export const getSlugFromPage = (page: PageDto) =>
  getSlugFromHref(page?.links?.find((e) => e.rel === 'redirect')?.href!);

export const getSlugFromHref = (href: string) =>
  href?.match('therapies/(.*)')![1]!;

export const sanitizeSlug = (slug: string) => {
  const _slug = slug as string;
  const _slugArr = _slug?.split('-');
  const setIdSlug = _slugArr?.[_slugArr?.length - 1] ?? '';

  if (setIdSlug?.length === 10 && setIdSlug.match(/\w+abc/)) {
    return _slug?.slice(0, _slug?.length - 3);
  }
  return slug;
};

export const isValidCSRBrandSlug = (brandSlug: string) => {
  const setIdSlugArr = brandSlug.split('-');
  const setIdSlug = setIdSlugArr[setIdSlugArr?.length - 1];
  return setIdSlug?.length === 10 && !!setIdSlug.match(/\w+abc/);
};

/**
 * Generates a slug from a given name.
 * Example: 'Hypertension Treatment' -> 'hypertension-treatment'
 * @param name - The string to convert into a slug
 * @returns The generated slug
 */
export function generateSlug(name: string): string {
  // Normalize unicode characters and convert to ASCII
  const normalized = name
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  // Remove special characters except spaces and hyphens
  const cleaned = normalized.replace(/[^\w\s-]/g, '').trim();

  // Replace spaces and multiple hyphens with single hyphen
  return cleaned.replace(/[-\s]+/g, '-');
}
