'use client';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

interface DrugInteractionsProviderProps {
  children: ReactNode;
}

interface IDrugInteractionContext {
  isOpen: boolean;
  setIsOpen: (_bool: boolean) => void;
}

const defaultState: IDrugInteractionContext = {
  isOpen: false,
  setIsOpen: () => {},
};

const DrugInteractionsContext = createContext(defaultState);

export const useDrugInteractionsContext = () =>
  useContext(DrugInteractionsContext);

export const DrugInteractionsProvider: FC<DrugInteractionsProviderProps> = (
  props,
) => {
  const [isOpen, setIsOpen] = useState(false);

  const values = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen],
  );

  return <DrugInteractionsContext.Provider value={values} {...props} />;
};
