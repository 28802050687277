import {useMutation} from '@tanstack/react-query';
import {RepUpdateDto} from '@/models';
import {getLabelsBySetIds} from '@/queries/useLabel';
import {create} from '@/services/api';

export interface IRepCreateCustomer {
  name: string;
  repId?: string;
  brands: string[];
}

const API_VERSION = 2;

async function createRepCustomerWithBrandDetails(params: IRepCreateCustomer) {
  try {
    const labels = await getLabelsBySetIds(params.brands);
    const brands = labels?.map((label) => ({
      setId: label.setId,
      name: label.title,
      slug: label.slugId,
      manufacturer: label.manufacturer,
    }));
    return await create<RepUpdateDto>(
      'Customer',
      {
        params: {
          ...params,
          brands,
        },
      },
      {},
      'samples',
      true,
      API_VERSION,
    );
  } catch (error) {
    console.error('Error creating rep customer with brand details:', error);
    throw error;
  }
}

export function useCreateRepCustomer(options = {}) {
  return useMutation({mutationFn: createRepCustomerWithBrandDetails, ...options});
}
