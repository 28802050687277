import {usePathname} from 'next/navigation';
import {getIsPDP} from '@/utils/pdp';

function useIsPDP() {
  const pathname = usePathname();
  const pathToEvaluate = pathname ?? window?.location.pathname;

  return getIsPDP(pathToEvaluate);
}

export default useIsPDP;
