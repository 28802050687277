import {useMutation, useQueryClient} from '@tanstack/react-query';
import {DLVUser} from '@/constants';
import {fetchHCPUser} from '@/queries/useHCPUser';
import {create} from '@/services/api';

export interface LoginFrictionless {
  userId: string;
  tenantId: string;
  firstName: string;
  lastName: string;
  email: string;
  accessToken: string;
  expiresIn: number;
  redirectTo: string | null;
  picture: string;
}
export interface ICheckMagicLinkToLogin {
  token: string;
}

async function checkMagicLinkToLogin(params: ICheckMagicLinkToLogin) {
  return await create<LoginFrictionless>(
    'VerifyPasswordLessSignIn',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
  );
}

export function useCheckMagicLinkToLogin(
  onSuccessCallback?: (_data: DLVUser) => void,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: checkMagicLinkToLogin,
    onSuccess: async (res: LoginFrictionless) => {
      if (res.email) {
        const data = await fetchHCPUser(queryClient, res.accessToken);
        onSuccessCallback?.({
          user: {
            npi: data?.npi ?? '',
            email: res.email,
            firstName: res.firstName,
            lastName: res.lastName,
          },
        });
      }
    },
  });
}
