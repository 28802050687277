import {IEnhancedContent, IEnhancedContentSanity} from '@/models';

export const getEnhancedContentValues = (
  enhancedContent?: IEnhancedContent | IEnhancedContentSanity | null,
) => {
  const disableAi = enhancedContent?.disableBrandAi ?? false;
  const samplesAvailable = enhancedContent?.samplesAvailable ?? false;
  const disableBrandFaq = enhancedContent?.disableBrandFaq ?? false;
  const isCoverageEnabled = enhancedContent?.enableSanityEnhanced
    ? !(enhancedContent as IEnhancedContentSanity).disabledCoverage
    : ((enhancedContent as IEnhancedContent)?.coverageEnabled ?? true);
  const disableMostRecentPaFormsSection =
    enhancedContent?.disableMostRecentPaFormsSection ?? false;
  const disableBrandBookmarking =
    enhancedContent?.disableBrandBookmarking ?? false;
  const disableBrandShare = enhancedContent?.disableBrandShare ?? false;
  const disableBrandMsl = enhancedContent?.disableBrandMsl ?? false;
  const disableBrandDosage = enhancedContent?.disableBrandDosage ?? false;
  const disableBrandPatientEducation =
    enhancedContent?.disableBrandPatientEducation ?? false;
  const disableBrandLabel = enhancedContent?.disableBrandLabel ?? false;
  const disableBrandPriorAuthorizationForms =
    enhancedContent?.disableBrandPriorAuthorizationForms ?? false;
  const disableBrandFinancialAssistancePrograms =
    enhancedContent?.disableBrandFinancialAssistancePrograms ?? false;
  const disableBrandPubMed = enhancedContent?.disableBrandPubMed ?? false;
  const hasSanityFinancialAssistancePrograms =
    enhancedContent?.hasSanityFinancialAssistancePrograms ?? null;
  const featuredHero = enhancedContent?.featuredHero ?? null;
  const customHero = enhancedContent?.customHero ?? null;

  return {
    disableAi,
    samplesAvailable,
    disableBrandFaq,
    isCoverageEnabled,
    disableMostRecentPaFormsSection,
    disableBrandBookmarking,
    disableBrandShare,
    disableBrandMsl,
    disableBrandDosage,
    disableBrandPatientEducation,
    disableBrandLabel,
    disableBrandPriorAuthorizationForms,
    disableBrandFinancialAssistancePrograms,
    disableBrandPubMed,
    hasSanityFinancialAssistancePrograms,
    featuredHero,
    customHero,
  };
};
