import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {EnhancedDrugDto} from '@/models';
import {read} from '@/services/api';

export async function getEnhancedDrugs(): Promise<EnhancedDrugDto[]> {
  let data: EnhancedDrugDto[] = [];
  try {
    data = await read<EnhancedDrugDto[]>(
      'enhancedDrugs',
      {parentName: 'contentful'},
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export const ENHANCED_DRUGS_QUERY_KEY = ['enhanced-drugs'];

export function useEnhancedDrugs(options = {}) {
  return useQuery<EnhancedDrugDto[]>({
    queryKey: ENHANCED_DRUGS_QUERY_KEY,
    queryFn: async () => await getEnhancedDrugs(),
    placeholderData: [],
    ...options,
  });
}

export function getEnhancedDrugsPrefetchQuery(): IPrefetchedQuery {
  return {
    queryKey: ENHANCED_DRUGS_QUERY_KEY,
    getFn: () => getEnhancedDrugs(),
  };
}
