import { useCurrentSlug } from './useCurrentSlug';
import { useEnhanced } from './useEnhanced';
import * as FLAGS from '@/constants/flags';
import { useFlag } from '@/context';
import { useABExperiment } from '@/context/ABExperimentContext';
import { useDrugIndications } from '@/queries/useDrugIndications';
import { useLabel } from '@/queries/useLabel';
import { checkIsPfizerDrug } from '@/utils/product';

/**
 * Determines if the coverage section should be highlighted in the UI
 * @param showCoverageOnPdp - Whether coverage tool is enabled for PDP
 * @param featuredHero - The currently featured hero section, if any
 * @param samplesAvailable - Whether samples are available for this drug
 * @returns boolean indicating if coverage section should be highlighted
 *
 * This function checks if the coverage section should be highlighted based on the following criteria:
 * 1. If the coverage tool is not enabled for PDP, return false
 * 2. If there is no featured hero section or the featured hero section is 'samples' and samples are not available, return true
 * 3. If the featured hero section is 'coverage', return true
 * 4. Otherwise, return false
 */
const getIsCoverageHighlighted = (showCoverageOnPdp: boolean, featuredHero: string | null, samplesAvailable: boolean) => {
  if (!showCoverageOnPdp) return false;
  if ((!featuredHero || featuredHero === 'samples') && !samplesAvailable) return true;
  if (featuredHero === 'coverage') return true;
  return false;
};

/**
 * Determines if the coverage section should be shown in the sidebar
 * @param newVersionDS - Whether the new version of the drug detail page is enabled
 * @param showCoverageOnPdp - Whether coverage tool is enabled for PDP
 * @param featuredHero - The currently featured hero section, if any
 * @param samplesAvailable - Whether samples are available for this drug
 * @returns boolean indicating if coverage section should be shown in the sidebar
 *
 * This function checks if the coverage section should be shown in the sidebar based on the following criteria:
 * 1. If the new version of the drug detail page is not enabled, return the value of showCoverageOnPdp
 * 2. If the coverage tool is not enabled for PDP, return false
 * 3. If there is no featured hero section or the featured hero section is 'samples' and samples are not available, return false
 * 4. If the featured hero section is 'coverage', return false
 * 5. Otherwise, return true
 */
const getShowCoverageInSidebar = (newVersionDS: boolean, showCoverageOnPdp: boolean, featuredHero: string | null, samplesAvailable: boolean) => {
  if (!newVersionDS) return showCoverageOnPdp;
  if (!showCoverageOnPdp) return false;
  if ((!featuredHero || featuredHero === 'samples') && !samplesAvailable) return false;
  if (featuredHero === 'coverage') return false;
  return true;
};

export function useIsCoverageToolAvailable() {
  const { isNewVersion: newVersionDS } = useABExperiment();
  const { setId, isConstellationDrug } = useCurrentSlug();
  const { isCoverageEnabled, samplesAvailable, featuredHero } = useEnhanced(setId);
  const { data: label } = useLabel(setId);

  const isPfizerDrug = checkIsPfizerDrug(label);

  const showCoverageOnPdpFlag = useFlag(FLAGS.COVERAGE_ON_PDP);
  const showCoverageLandPageFlag = useFlag(FLAGS.COVERAGE_PAGE);

  const enableCoverageForConstellationOnly = useFlag(
    FLAGS.COVERAGE_FOR_CONSTELLATION,
  );

  const showCoveragePerTypeOfDrug = enableCoverageForConstellationOnly
    ? isConstellationDrug
    : true;

  const { data: indications = [] } = useDrugIndications(setId, {
    enabled: !!setId,
  });

  const hasIndications = indications.length > 0;

  const shouldBeEnabled =
    isCoverageEnabled &&
    showCoveragePerTypeOfDrug &&
    hasIndications &&
    !isPfizerDrug;

  const showCoverageOnPdp = shouldBeEnabled && showCoverageOnPdpFlag;
  const showCoverageLandPage = shouldBeEnabled && showCoverageLandPageFlag;
  const showCoverageInSidebar = getShowCoverageInSidebar(newVersionDS, showCoverageOnPdp, featuredHero, samplesAvailable);
  const isCoverageHighlighted = getIsCoverageHighlighted(showCoverageOnPdp, featuredHero, samplesAvailable);

  return {
    showCoverageOnPdp,
    showCoverageLandPage,
    showCoverage:
      shouldBeEnabled && (showCoverageOnPdp || showCoverageLandPage),
    isCoverageHighlighted,
    showCoverageInSidebar
  };
}
