'use client';
import React, {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {useLargeFilter, UseLargeFilterReturn} from '@/hooks';
import {useQueryParams} from '@/hooks/useQueryParams';
import useRangeFilter, {
  rangesInitialState,
  UseRangeFilterProps,
} from '@/hooks/useRangeFilter';
import useSliderFilter, {UseSliderFilterProps} from '@/hooks/useSliderFilter';
import {useToggleFilter, UseToggleFilterReturn} from '@/hooks/useToggleFilter';
import {FacetValues} from '@/models';
import {financialAssistanceFiltersQueryParams} from '@/utils/financialAssistance';

type FinancialAssistanceFiltersContextType = {
  resetFilters: () => void;
  mobileModalOpen: boolean;
  handleMobileModalClose: () => void;
  handleMobileModalOpen: () => void;
  insuranceRequirementsFilter: UseLargeFilterReturn;
  coverageRequiredFilter: UseToggleFilterReturn;
  ageRequiredFilter: UseToggleFilterReturn;
  fundedProgramsFilter: UseToggleFilterReturn;
  outOfPocketFilter: UseRangeFilterProps;
  maxSavingsFilter: UseSliderFilterProps;
  payAsLowsMaxValue?: number;
  savingsMaxValue?: number;
  isMaxSavingsFacetValuesEmpty?: boolean;
  isPayAsLowFacetValuesEmpty?: boolean;
  isFundedFacetValuesEmpty?: boolean;
};

const FinancialAssistanceFiltersContext = createContext<
  FinancialAssistanceFiltersContextType | undefined
>(undefined);

type FinancialAssistanceFiltersProviderProps = {
  children: ReactNode;
  insuranceRequirementsFacetValues?: FacetValues;
  payAsLowFacetValues?: FacetValues;
  fundedFacetValues?: FacetValues;
  maxSavingsFacetValues?: FacetValues;
};

export const FinancialAssistanceFiltersProvider: FC<
  FinancialAssistanceFiltersProviderProps
> = ({
  children,
  insuranceRequirementsFacetValues,
  payAsLowFacetValues,
  fundedFacetValues,
  maxSavingsFacetValues,
}) => {
  const {searchParams, deleteParams, setParams} = useQueryParams();

  const insuranceRequirementsFilter = useLargeFilter({
    facetValues: insuranceRequirementsFacetValues,
    filterQueryParam:
      financialAssistanceFiltersQueryParams.insuranceRequirements,
  });

  const getBooleanFromSearchParams = useCallback(
    (key: string) => {
      const value = searchParams?.get(key);
      return typeof value === 'string' ? value === 'true' : false;
    },
    [searchParams],
  );

  const isEmptyObject = (obj?: Record<string, unknown>) =>
    !obj || Object.keys(obj).length === 0;

  const isMaxSavingsFacetValuesEmpty = useMemo(
    () => isEmptyObject(maxSavingsFacetValues),
    [maxSavingsFacetValues],
  );
  const isPayAsLowFacetValuesEmpty = useMemo(
    () => isEmptyObject(payAsLowFacetValues),
    [payAsLowFacetValues],
  );
  const isFundedFacetValuesEmpty = useMemo(
    () => isEmptyObject(fundedFacetValues),
    [fundedFacetValues],
  );

  const savingsMaxValue = useMemo(() => {
    if (!maxSavingsFacetValues || isMaxSavingsFacetValuesEmpty) return 10000;

    return Math.max(...Object.keys(maxSavingsFacetValues).map(Number));
  }, [maxSavingsFacetValues, isMaxSavingsFacetValuesEmpty]);

  const payAsLowsMaxValue = useMemo(() => {
    if (!payAsLowFacetValues || isPayAsLowFacetValuesEmpty) return 100;

    return Math.max(...Object.keys(payAsLowFacetValues).map(Number));
  }, [payAsLowFacetValues, isPayAsLowFacetValuesEmpty]);

  const f1InitialState = useMemo(
    () =>
      getBooleanFromSearchParams(
        financialAssistanceFiltersQueryParams.coverageRequired,
      ),
    [getBooleanFromSearchParams],
  );

  const f2InitialState = useMemo(
    () =>
      getBooleanFromSearchParams(
        financialAssistanceFiltersQueryParams.ageRequired,
      ),
    [getBooleanFromSearchParams],
  );

  const f3InitialState = useMemo(
    () =>
      getBooleanFromSearchParams(
        financialAssistanceFiltersQueryParams.fundedPrograms,
      ),
    [getBooleanFromSearchParams],
  );

  const coverageRequiredFilter = useToggleFilter({
    initialState: f1InitialState,
    onFilterChange: (checked) => {
      setParams({
        [financialAssistanceFiltersQueryParams.coverageRequired]: checked
          ? 'true'
          : 'false',
      });
    },
  });

  const ageRequiredFilter = useToggleFilter({
    initialState: f2InitialState,
    onFilterChange: (checked) => {
      setParams({
        [financialAssistanceFiltersQueryParams.ageRequired]: checked
          ? 'true'
          : 'false',
      });
    },
  });

  const fundedProgramsFilter = useToggleFilter({
    initialState: f3InitialState,
    onFilterChange: (checked) => {
      setParams({
        [financialAssistanceFiltersQueryParams.fundedPrograms]: checked
          ? 'true'
          : 'false',
      });
    },
  });

  const outOfPocketFilter = useRangeFilter({
    initialRanges: payAsLowsMaxValue
      ? {...rangesInitialState, max: payAsLowsMaxValue}
      : rangesInitialState,
    paramKey: financialAssistanceFiltersQueryParams.outOfPocket,
  });

  const maxSavingsFilter = useSliderFilter({
    paramKey: financialAssistanceFiltersQueryParams.maxSavings,
  });

  const [mobileModalOpen, setMobileModalOpen] = useState(false);

  const handleMobileModalOpen = useCallback(() => {
    setMobileModalOpen(true);
  }, [setMobileModalOpen]);

  const handleMobileModalClose = useCallback(() => {
    setMobileModalOpen(false);
  }, [setMobileModalOpen]);

  const resetFilters = useCallback(() => {
    insuranceRequirementsFilter.uncheckAllLargeFilterOptions();
    coverageRequiredFilter.setFilterState(false);
    ageRequiredFilter.setFilterState(false);
    fundedProgramsFilter.setFilterState(false);
    outOfPocketFilter.resetRangeFilters();
    maxSavingsFilter.resetRangeFilters();
    deleteParams([
      financialAssistanceFiltersQueryParams.insuranceRequirements,
      financialAssistanceFiltersQueryParams.coverageRequired,
      financialAssistanceFiltersQueryParams.ageRequired,
      financialAssistanceFiltersQueryParams.fundedPrograms,
      financialAssistanceFiltersQueryParams.outOfPocket,
      financialAssistanceFiltersQueryParams.maxSavings,
    ]);
  }, [
    insuranceRequirementsFilter,
    coverageRequiredFilter,
    ageRequiredFilter,
    fundedProgramsFilter,
    maxSavingsFilter,
    outOfPocketFilter,
    deleteParams,
  ]);

  const value = useMemo(
    () => ({
      resetFilters,
      mobileModalOpen,
      handleMobileModalClose,
      handleMobileModalOpen,
      insuranceRequirementsFilter,
      coverageRequiredFilter,
      ageRequiredFilter,
      fundedProgramsFilter,
      outOfPocketFilter,
      maxSavingsFilter,
      payAsLowsMaxValue,
      savingsMaxValue,
      isMaxSavingsFacetValuesEmpty,
      isPayAsLowFacetValuesEmpty,
      isFundedFacetValuesEmpty,
    }),
    [
      resetFilters,
      mobileModalOpen,
      handleMobileModalClose,
      handleMobileModalOpen,
      insuranceRequirementsFilter,
      coverageRequiredFilter,
      ageRequiredFilter,
      fundedProgramsFilter,
      outOfPocketFilter,
      maxSavingsFilter,
      payAsLowsMaxValue,
      savingsMaxValue,
      isMaxSavingsFacetValuesEmpty,
      isPayAsLowFacetValuesEmpty,
      isFundedFacetValuesEmpty,
    ],
  );

  return (
    <FinancialAssistanceFiltersContext.Provider value={value}>
      {children}
    </FinancialAssistanceFiltersContext.Provider>
  );
};

export const useFinancialAssistanceFilters = () => {
  const context = useContext(FinancialAssistanceFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useFinancialAssistanceFilters must be used within a FinancialAssistanceFiltersProvider',
    );
  }
  return context;
};
