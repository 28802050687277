import {useCallback, useEffect, useState} from 'react';
import useDebounce from './useDebounce';
import {useQueryParams} from './useQueryParams';

export type UseSliderFilterProps = {
  amount: number | null;
  setAmount: (_amount: number) => void;
  handleChange: (_amount: number) => void;
  resetRangeFilters: () => void;
};

const useSliderFilter = ({
  initialAmount,
  paramKey,
}: {
  initialAmount?: number;
  paramKey: string;
}): UseSliderFilterProps => {
  const {setParams, searchParams} = useQueryParams();
  const [amount, setAmount] = useState<number | null>(initialAmount ?? null);
  const [hasChanged, setHasChanged] = useState(false);

  const debouncedAmount = useDebounce(amount, 500);

  useEffect(() => {
    const key = searchParams.get(paramKey);
    if (key) {
      setAmount(parseInt(key));
    }
  }, [paramKey, searchParams]);

  useEffect(() => {
    if (debouncedAmount !== null && hasChanged) {
      setParams({[paramKey]: debouncedAmount.toString()});
    }
  }, [debouncedAmount, paramKey, setParams, hasChanged]);

  const handleChange = useCallback((newAmount: number) => {
    setAmount(newAmount);
    setHasChanged(true);
  }, []);

  const resetRangeFilters = useCallback(() => {
    setAmount(null);
    setHasChanged(false);
  }, []);

  return {
    amount,
    setAmount,
    handleChange,
    resetRangeFilters,
  };
};

export default useSliderFilter;
