export const fetchFinancialAssistanceProgramQuery = `*[_type == "financialAssistanceProgram" && _id == $slug][0]{
  _id,
  programName,
  programId,
  programType,
  outOfPocket,
  summary,
  coverageEligibilities,
  benefits {
      _type,
      monthlySavings,
      annualMaximumBenefit,
      maximumFills
  },
  requirements {
      _type,
      expirationDate,
      minimumAge,
      usResidency,
      enrollmentRequired,
      needsBased,
      activationRequired,
      renewalRequired,
      coverageRequired
  },
  programDetails[],
  resources[],
  faqs[],
}`;
