import {SCROLL_DEPTH_BREAKPOINTS} from '@/constants/scrollBreakpoints';

type IHandleScroll = (_oldPer: number, _curPer: number) => number | undefined;

export const getScrollDirection = (
  onScrollDown: IHandleScroll,
  onScrollUp: IHandleScroll,
) => {
  let oldPercentage = 0;
  return (currPercentage: number) => {
    if (currPercentage > oldPercentage) {
      const reachedPercentage = onScrollDown(oldPercentage, currPercentage);
      oldPercentage = currPercentage;
      return reachedPercentage;
    } else if (currPercentage <= oldPercentage) {
      const reachedPercentage = onScrollUp(oldPercentage, currPercentage);
      oldPercentage = currPercentage;
      return reachedPercentage;
    }
  };
};

export const handleScrollDown: IHandleScroll = (oldP, currP) => {
  let reachedBreakpoint = SCROLL_DEPTH_BREAKPOINTS.find((breakpoint) => {
    if (oldP < breakpoint && currP >= breakpoint) {
      return breakpoint;
    }
  });
  if (reachedBreakpoint) {
    return reachedBreakpoint;
  }
};

export const handleScrollUp: IHandleScroll = (oldP, currP) => {
  let reachedBreakpoint = SCROLL_DEPTH_BREAKPOINTS.find((breakpoint) => {
    if (oldP > breakpoint && currP <= breakpoint) {
      return breakpoint;
    }
  });
  if (reachedBreakpoint) {
    return reachedBreakpoint;
  }
};

export const getScrollSpeed = (function () {
  let lastPos: number | null,
    newPos,
    timer: NodeJS.Timeout,
    delta: number,
    delay = 50; // in "ms" (higher means lower fidelity )

  function clear() {
    lastPos = null;
    delta = 0;
  }

  clear();

  return function () {
    newPos = window.scrollY;
    if (lastPos != null) {
      delta = newPos - lastPos;
    }
    lastPos = newPos;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
})();

export const scrollToSection = (anchor: string) => {
  const element = document.getElementById(anchor);
  element?.scrollIntoView({behavior: 'smooth', block: 'start'});
};
