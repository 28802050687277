'use client';
import {FC, createContext, useContext, ReactNode, useMemo} from 'react';

interface ABExperimentContextType {
  isNewVersion: boolean;
}

export const ABExperimentContext = createContext<ABExperimentContextType>({
  isNewVersion: false,
});

export const useABExperiment = () => useContext(ABExperimentContext);

interface ABExperimentProviderProps {
  children: ReactNode;
  isNewVersion: boolean;
}

export const ABExperimentProvider: FC<ABExperimentProviderProps> = ({
  children,
  isNewVersion,
}) => {

  const contextValue = useMemo(() => ({ isNewVersion }), [isNewVersion]);

  return (
    <ABExperimentContext.Provider value={contextValue}>
      {children}
    </ABExperimentContext.Provider>
  );
};
